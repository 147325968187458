export const theme = {
  primaryGreener: '#f5f5f5',
  primaryFloristic: '#F0E9F1',
  white: 'hsl(0,0%,100%)',
  grey: 'hsl(0,0%,60%)',
  lightgrey: 'hsl(0,0%,80%)',
  verylightgrey: 'hsl(0,0%,99%)',
  black: 'hsl(0,0%,0%)',
  logoRed: 'hsl(0,100%,50%)',
  logoGreen: 'hsl(137.7,100%,32%)',
  font: {
    size: {
      xs: '1.2rem',
      s: '1.6rem',
      m: '2.6rem',
      l: '4.2rem',
      xl: '7.2rem',
      xxl: '9.4rem',
    },
    weight: {
      light: '300',
      regular: '400',
      bold: '500',
    },
  },
  mq: {
    tablet: `@media (min-width: 768px)`,
    desktop: `@media (min-width: 1024px)`,
    huge: `@media (min-width: 1440px)`,
  },
};
