import React from "react"
import { ThemeProvider } from 'styled-components';
import {theme} from "../styles/theme";
import GlobalStyles from "../styles/GlobalStyles";

const Layout = ({children}) => (
  <>
    <GlobalStyles/>
    <ThemeProvider theme={theme}>
        <>
        {children}
        </>
    </ThemeProvider>
  </>
)

export default Layout
